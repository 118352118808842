<template>
  <div>
    <v-btn icon small>
      <div
        class="d-flex align-center flex-column"
        style="cursor: pointer"
        @click="modalShow('langSelector')"
      >
        <v-icon> mdi-translate </v-icon>
        <div class="text-caption text-capitalize">{{ langText }}</div>
      </div>
    </v-btn>
    <frame-modal name="langSelector">
      <v-card>
        <v-card-text class="pt-5">
          <v-list>
            <v-list-item-group
              v-model="lang"
              color="primary"
              @change="changeLang"
            >
              <template v-for="(lang, i) in langs">
                <v-list-item
                  :key="`lang-${i}`"
                  class="px-2"
                  :value="lang.value"
                >
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="lang.label"
                      class="font-weight-medium"
                      style="font-size: 16px"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i < langs.length - 1" :key="i"></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
      </v-card>
    </frame-modal>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import FrameModal from "@/components/FrameModal.vue";

export default {
  components: {
    FrameModal,
  },
  computed: {
    langText() {
      return this.langs.find((d) => d.value == this.lang)?.label;
    },
  },
  methods: {
    changeLang() {
      const query = cloneDeep(this.$route.query);
      query.lang = this.lang;
      this.$router.push({ query: query }, () => {});
      this.modalClose();
    },
  },
};
</script>

<style>
#app .lang-selector input {
  font-size: 16px !important;
}
</style>
