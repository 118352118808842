<template>
  <div>
    <v-form
      v-if="!search.smallPanel"
      ref="form"
      v-model="form.valid"
      lazy-validation
      :disabled="form.loading"
    >
      <v-sheet
        elevation="2"
        class="rounded-xl px-4 pt-6 pb-5"
        color="grey lighten-5"
        rounded
      >
        <DatePicker v-model="dateRange" class="mb-3" />
        <RoomMapSelector v-model="search.roomMap" class="mb-3" />
        <AreaSelectorForMobile
          v-model="search.selectedArea"
          :areas="areas"
          :isThirdLayerSet="isThirdLayerSet"
          range
          class="mb-3"
        />
        <HotelSelector
          v-model="search.hotelId"
          :items="portalHotels"
          class="mb-3"
        />
        <TextSearchField
          v-model="search.promotionCode"
          type="promotionCode"
          class="mb-3"
        />

        <div class="d-flex justify-end">
          <FilterSelector
            v-model="search"
            :hotelTypes="hotelTypes"
            :hotelTags="hotelTags"
            :roomTags="roomTags"
            :meals="meals"
            style="height: 40px"
          />
          <v-btn
            class="font-weight-bold text-subtitle-1 ml-3"
            color="primary"
            style="width: 120px; height: 40px"
            large
            :disabled="!form.valid || form.loading"
            data-test="search-btn"
            @click="_search()"
          >
            {{ $t("plans.searchPanel.search") }}
          </v-btn>
        </div>
      </v-sheet>
    </v-form>
    <SearchSmallPanelForMobile
      v-else
      v-model="search"
      :areas="areas"
      :portalHotels="portalHotels"
      :meals="meals"
      :isThirdLayerSet="isThirdLayerSet"
      :form="form"
      @search="_search"
    />
  </div>
</template>

<script>
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import AreaSelectorForMobile from "./SearchPanel/AreaSelectorForMobile";
import DatePicker from "./SearchPanel/DatePicker";
import FilterSelector from "./SearchPanel/FilterSelector.vue";
import RoomMapSelector from "./SearchPanel/RoomMapSelector.vue";
import TextSearchField from "./SearchPanel/TextSearchField";
import HotelSelector from "./SearchPanel/HotelSelector";
import SearchSmallPanelForMobile from "./SearchPanel/SearchSmallPanelForMobile";

export default {
  components: {
    AreaSelectorForMobile,
    DatePicker,
    FilterSelector,
    RoomMapSelector,
    TextSearchField,
    HotelSelector,
    SearchSmallPanelForMobile,
  },
  data: () => ({
    form: {
      valid: true,
      loading: false,
    },
  }),
  props: {
    value: { type: Object, required: true },
    areas: { type: Array },
    portalHotels: { type: Array },
		hotelTypes: { type: Array },
		hotelTags: { type: Array },
    roomTags: { type: Array },
    meals: { type: Array },
    isThirdLayerSet: { type: Boolean },
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(items) {
        this.$emit("input", items);
      },
    },
    dateRange: {
      get() {
        return [this.search.checkedInOn, this.search.checkedOutOn].filter((val) => val);
      },
      set(val) {
        this.search.checkedInOn = val[0];
        this.search.checkedOutOn = val[1];
      },
    },
    nextQuery() {
      const searchNight = this.portalDefaultSetting?.defaultCondition?.nights || 1
      let query = {
        checkedInOn: this.search.checkedInOn,
        checkedOutOn:
          this.search.checkedOutOn ||
          dayjs(this.search.checkedInOn)
            .add(searchNight, "days")
            .format("YYYY-MM-DD"),
        night: dayjs(
          this.search.checkedOutOn ||
            dayjs(this.search.checkedInOn)
              .add(searchNight, "days")
              .format("YYYY-MM-DD")
        )
          .clone()
          .diff(dayjs(this.search.checkedInOn), "days"),
        roomMap: this.search.roomMap.map((r) => r).join(","),
        meal:
          this.search.selectedMeals && this.search.selectedMeals.length
            ? this.search.selectedMeals.join(",")
            : "",
        hotelId: this.search.hotelId ? this.search.hotelId.trim() : "",
        portalSiteSpecialCode: this.search.promotionCode
          ? this.search.promotionCode.trim()
          : "",
        areaIds:
          this.search.selectedArea.length &&
          this.search.selectedArea[0] &&
          this.search.selectedArea[0].id
            ? this.search.selectedArea
                .map((d) => d.id)
                .filter(Boolean)
                .join(",")
            : "",
        hotelTypeIds:
          this.search.selectedHotelTypes &&
          this.search.selectedHotelTypes.length
            ? this.search.selectedHotelTypes.join(",")
            : "",
        hotelTagIds:
          this.search.selectedHotelTags && this.search.selectedHotelTags.length
            ? this.search.selectedHotelTags.join(",")
            : "",
        roomTagIds:
          this.search.selectedRoomTags && this.search.selectedRoomTags.length
            ? this.search.selectedRoomTags.join(",")
            : "",
        near: "true",
      };
      if (this.$vuetify.breakpoint.xs) this.$set(query, "smallPanel", "true");
      return query;
    },
  },
  methods: {
    async _search() {
      try {
        let query = cloneDeep(this.$route.query);
        const newQuery = {
          ...query,
          ...this.nextQuery,
        };
        const k = /,|@|-/g;
        const pax = this.nextQuery.roomMap.split(k).length;
        if (this.$route.query.pax) newQuery.pax = pax;
        if (newQuery.near) newQuery.near = "false";
        await this.$router.push({ query: newQuery }, () => {});
        this.$emit("search");
        this.search.smallPanel = true;
        this.$emit("small-panel", true);
      } catch (e) {
        console.log(e);
      }
    },
    resetFilters() {
      Object.keys(this.search.detailParams).map((k) => {
        this.search.detailParams[k] = "";
      });
    },
  },
};
</script>