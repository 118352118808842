<template>
  <v-dialog
    v-bind="$attrs"
    :width="width"
    :value="isOpen"
    :fullscreen="fullscreen"
    :hide-overlay="hideOverlay"
    :persistent="!closeOnClickOutside"
    @click="close()"
    @click:outside="closeOnClickOutside ? close() : ''"
  >
    <slot name="default"></slot>
  </v-dialog>
</template>
<script>
export default {
  props: {
    width: { type: Number, default: 600 },
    fullscreen: { type: Boolean, default: false },
    hideOverlay: { type: Boolean, default: false },
    // scrollable: { type: Boolean, default }
    name: { type: String },
    closeOnClickOutside: { type: Boolean, default: true }
  },
  computed: {
    isOpen() {
      return this.modalCheck(this.name)
    }
  },
  methods: {
    close() {
      this.modalClose();
      this.$emit('modal-close')
    }
  }
}
</script>