<template>
  <v-form
    ref="form"
    v-model="form.valid"
    lazy-validation
    :disabled="form.loading"
  >
    <!-- タブレット 600-1264 -->
    <v-sheet
      v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.md"
      elevation="2"
      class="px-8 pt-7 pb-5"
      color="rgb(255, 255, 255, 0.8)"
      rounded
    >
      <div class="d-flex align-center mb-3" style="width: 100%">
        <DatePicker
          v-model="dateRange"
          range
          style="width: 50%"
        />
        <RoomMapSelector
          v-model="search.roomMap"
          class="ml-2"
          style="width: 50%"
        />
      </div>
      <AreaSelector
        v-model="search.selectedArea"
        :areas="areas"
        :isThirdLayerSet="isThirdLayerSet"
        range
        class="mb-3"
        :label="$t('portal.lengthOfStay')"
      />
      <div class="d-flex align-center mb-3" style="width: 100%">
        <HotelSelector
          v-model="search.hotelId"
          :items="portalHotels"
          style="width: 50%"
        />
        <TextSearchField
          v-model="search.promotionCode"
          type="promotionCode"
          style="width: 50%"
          class="ml-2"
        />
      </div>
      <div class="d-flex align-end justify-end">
        <FilterSelector
          v-model="search"
          :hotelTypes="hotelTypes"
          :hotelTags="hotelTags"
          :roomTags="roomTags"
          :meals="meals"
        />
        <v-btn
          class="font-weight-bold text-subtitle-1"
          color="primary"
          style="width: 120px; height: 45px"
          large
          :disabled="!form.valid || form.loading"
          @click="_search()"
        >
          {{ $t('plans.searchPanel.search') }}
        </v-btn>
      </div>
    </v-sheet>

    <!-- パソコン 1264- -->
    <v-sheet
      v-else
      elevation="2"
      class="px-7 pt-7 pb-6"
      style="width: 1250px"
      color="rgb(255, 255, 255, 0.8)"
      rounded
    >
      <div class="d-flex align-end" style="width: 100%">
        <DatePicker
          v-model="dateRange"
          range
          class="mr-3"
          style="width: 26%"
        />
        <RoomMapSelector v-model="search.roomMap" class="mr-3" style="width: 20%" />
        <AreaSelector
          v-model="search.selectedArea"
          :areas="areas"
          :isThirdLayerSet="isThirdLayerSet"
          range
          :label="$t('portal.lengthOfStay')"
          style="width: 59%"
        />
      </div>
      <div class="d-flex align-end justify-space-between mt-5">
        <div class="d-flex align-center">
          <div>
            <HotelSelector
              v-model="search.hotelId"
              :items="portalHotels"
              style="width: 350px"
            />
          </div>
          <div class="ml-3">
            <TextSearchField
              v-model="search.promotionCode"
              type="promotionCode"
              style="width: 350px"
            />
          </div>
        </div>
        <div class="d-flex align-end">
          <FilterSelector
            v-model="search"
            :hotelTypes="hotelTypes"
            :hotelTags="hotelTags"
            :roomTags="roomTags"
            :meals="meals"
          />
          <v-btn
            class="font-weight-bold text-subtitle-1"
            color="primary"
            style="width: 120px; height: 45px"
            large
            :disabled="!form.valid || form.loading"
            @click="_search()"
          >
            {{ $t('plans.searchPanel.search') }}
          </v-btn>
        </div>
      </div>
    </v-sheet>
  </v-form>
</template>

<script>
import dayjs from 'dayjs'
import { cloneDeep } from 'lodash';
import AreaSelector from './SearchPanel/AreaSelector'
import DatePicker from './SearchPanel/DatePicker'
import FilterSelector from './SearchPanel/FilterSelector.vue'
import RoomMapSelector from './SearchPanel/RoomMapSelector.vue'
import TextSearchField from './SearchPanel/TextSearchField'
import HotelSelector from './SearchPanel/HotelSelector'

export default {
  components: {
    AreaSelector,
    DatePicker,
    FilterSelector,
    RoomMapSelector,
    TextSearchField,
    HotelSelector
  },
  data: () => ({
    form: {
      valid: true,
      loading: false,
    },
  }),
  props: {
    value: { type: Object, required: true },
    areas: { type: Array },
    portalHotels: { type: Array },
		hotelTypes: { type: Array },
		hotelTags: { type: Array },
    roomTags: { type: Array },
    meals: { type: Array },
    isThirdLayerSet: { type: Boolean }
  },
  computed: {
    search: {
      get() {
        return this.value
      },
      set(items) {
        this.$emit('input', items)
      },
    },
    dateRange: {
      get() {
        return [this.search.checkedInOn, this.search.checkedOutOn].filter((val) => val)
      },
      set(val) {
        this.search.checkedInOn = val[0]
        this.search.checkedOutOn = val[1]
      },
    },
    nextQuery() {
      const searchNight = this.portalDefaultSetting?.defaultCondition?.nights || 1
      return {
        checkedInOn: this.search.checkedInOn,
        checkedOutOn: this.search.checkedOutOn || dayjs(this.search.checkedInOn).add(searchNight, "days").format("YYYY-MM-DD"),
        night: dayjs(this.search.checkedOutOn || dayjs(this.search.checkedInOn).add(searchNight, "days").format("YYYY-MM-DD")).clone().diff(
          dayjs(this.search.checkedInOn),
          'days'
        ),
        roomMap: this.search.roomMap.map((r) => r).join(','),
        meal: this.search.selectedMeals && this.search.selectedMeals.length ? this.search.selectedMeals.join(",") : "",
        hotelId: this.search.hotelId ? this.search.hotelId.trim() : '',
        portalSiteSpecialCode: this.search.promotionCode ? this.search.promotionCode.trim() : '',
        areaIds: this.search.selectedArea.length && this.search.selectedArea[0] && this.search.selectedArea[0].id ? this.search.selectedArea.map(d => d.id).filter(Boolean).join(",") : "",
        hotelTypeIds: this.search.selectedHotelTypes && this.search.selectedHotelTypes.length ? this.search.selectedHotelTypes.join(",") : "",
        hotelTagIds: this.search.selectedHotelTags && this.search.selectedHotelTags.length ? this.search.selectedHotelTags.join(",") : "",
        roomTagIds: this.search.selectedRoomTags && this.search.selectedRoomTags.length ? this.search.selectedRoomTags.join(","): "",
        near: 'true',
      }
    },
  },
  methods: {
    async _search() {
      try {
        let query = cloneDeep(this.$route.query)
        const newQuery = {
          ...query,
          ...this.nextQuery,
        }
        const k = /,|@|-/g
        const pax = this.nextQuery.roomMap.split(k).length
        if (this.$route.query.pax) newQuery.pax = pax
        if (newQuery.near) newQuery.near = 'false'
        if (newQuery.smallPanel) this.$delete(newQuery, "smallPanel")
        await this.$router.push({ query: newQuery }, () => {})
        this.$emit('search')
      } catch (e) {
        console.log(e)
      }
    },
    resetFilters() {
      Object.keys(this.search.detailParams).map((k) => {
        this.search.detailParams[k] = ''
      })
    },
  },
}
</script>