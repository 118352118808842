<template v-if="search.smallPanel">
  <v-bottom-sheet v-model="dialog" inset>
    <template v-slot:activator="{ on, attrs }">
      <!-- 上部固定 -->
      <v-sheet
        v-bind="attrs"
        v-on="on"
        elevation="1"
        color="grey lighten-2"
        class="py-2 px-2"
      >
        <v-sheet class="d-flex pl-3 pr-4 py-1 text-body-2 rounded-xl" rounded>
          <div class="my-auto" style="width: 10%">
            <v-icon>mdi-magnify</v-icon>
          </div>
          <div style="width: 90%">
            <div class="d-flex" style="width: 100%">
              <div class="d-flex" style="width: 50%">
                <div>
                  <span class="text-caption grey--text text--darken-2">{{
                    $t("components.frameMain.checkInDate")
                  }}</span>
                  <div>{{ checkInDate }}</div>
                </div>
                <div class="ml-5">
                  <span class="text-caption grey--text text--darken-2">{{
                    $t("components.frameMain.nights")
                  }}</span>
                  <div>
                    {{
                      `${$t("common.nightsCount", { nights: search.night })}`
                    }}
                  </div>
                </div>
              </div>
              <div class="d-flex" style="width: 50%">
                <div>
                  <span class="text-caption grey--text text--darken-2">{{
                    $t("components.frameMain.person")
                  }}</span>
                  <div>
                    {{
                      $t("components.frameMain.personCount", {
                        totalAdults,
                        totalChildren,
                      })
                    }}
                  </div>
                </div>
                <div class="ml-5">
                  <span class="text-caption grey--text text--darken-2">{{
                    $t("components.frameMain.room")
                  }}</span>
                  <div>
                    {{
                      $t("components.frameMain.roomCount", {
                        roomCount: roomCount,
                      })
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="selectedAreaText || $route.query.portalSiteSpecialCode"
              class="d-flex justify-space-between mt-1"
              style="width: 100%"
            >
              <div v-if="selectedAreaText" style="width: 50%">
                <span class="text-caption grey--text text--darken-2">
                  {{ $t("portal.area") }}
                </span>
                <div>{{ selectedAreaText }}</div>
              </div>
              <div v-if="$route.query.portalSiteSpecialCode" style="width: 50%">
                <span class="text-caption grey--text text--darken-2">
                  {{ $t("portal.promotionCode") }}
                </span>
                <div>{{ $route.query.portalSiteSpecialCode }}</div>
              </div>
            </div>
            <div v-if="$route.query.hotelId" class="mt-1" style="width: 100%">
              <span class="text-caption grey--text text--darken-2">
                {{ $t("portal.facilityName") }}
              </span>
              <div>{{ hotelName }}</div>
            </div>
          </div>
        </v-sheet>
      </v-sheet>
    </template>
    <!-- ボトムシート -->
    <v-sheet
      elevation="2"
      class="pa-4"
      color="grey lighten-5"
      style="width: 100%"
      rounded
    >
      <v-btn icon class="mb-4" @click="dialog = false">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
      <DatePicker v-model="dateRange" class="mb-3" />
      <RoomMapSelector v-model="search.roomMap" class="mb-3" />
      <AreaSelectorForMobile
        v-model="search.selectedArea"
        :areas="areas"
        :isThirdLayerSet="isThirdLayerSet"
        range
        class="mb-3"
      />
      <HotelSelector
        v-model="search.hotelId"
        :items="portalHotels"
        class="mb-3"
      />
      <TextSearchField
        v-model="search.promotionCode"
        type="promotionCode"
        class="mb-3"
      />

      <div class="d-flex justify-end">
        <FilterSelector
          v-model="search"
          :hotelTypes="hotelTypes"
          :hotelTags="hotelTags"
          :roomTags="roomTags"
          :meals="meals"
          :smallPanel="search.smallPanel"
          :style="{
            height: $vuetify.breakpoint.xs ? 40 + 'px' : 45 + 'px',
          }"
        />
        <v-btn
          class="font-weight-bold text-subtitle-1 ml-3"
          color="primary"
          style="width: 120px"
          :style="{
            height: $vuetify.breakpoint.xs ? 40 + 'px' : 45 + 'px',
          }"
          large
          :disabled="!form.valid || form.loading"
          data-test="search-btn"
          @click="
            $emit('search');
            dialog = false;
          "
        >
          {{ $t("plans.searchPanel.search") }}
        </v-btn>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import AreaSelectorForMobile from "./AreaSelectorForMobile";
import DatePicker from "./DatePicker";
import FilterSelector from "./FilterSelector.vue";
import RoomMapSelector from "./RoomMapSelector.vue";
import TextSearchField from "./TextSearchField";
import HotelSelector from "./HotelSelector";

export default {
  components: {
    AreaSelectorForMobile,
    DatePicker,
    FilterSelector,
    RoomMapSelector,
    TextSearchField,
    HotelSelector,
  },
  data: () => ({
    dialog: false,
    roomCount: 1,
  }),
  props: {
    value: { type: Object, required: true },
    areas: { type: Array },
    portalHotels: { type: Array },
		hotelTypes: { type: Array },
		hotelTags: { type: Array },
    roomTags: { type: Array },
    meals: { type: Array },
    isThirdLayerSet: { type: Boolean },
    form: { type: Object },
  },
  async mounted() {
    if (!this.search.roomMap || !this.search.roomMap.length) return;
    const rooms = await Promise.all(
      this.search.roomMap.map(async (r) => {
        const paxArr = cloneDeep(r).split("-");
        const adults = await paxArr.filter((e) => e === "A");
        const children = await paxArr.filter((e) => e[0] === "C");
        return {
          numberOfAdults: adults.length,
          numberOfChildren: children.length,
        };
      })
    );
    this.roomCount = rooms.length;
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(items) {
        this.$emit("input", items);
      },
    },
    dateRange: {
      get() {
        return [this.search.checkedInOn, this.search.checkedOutOn].filter((val) => val);
      },
      set(val) {
        this.search.checkedInOn = val[0];
        this.search.checkedOutOn = val[1];
      },
    },
    checkInDate() {
      return dayjs(this.search.checkedInOn).format("YYYY/MM/DD");
    },
    totalAdults() {
      const roomMapJoin = this.search.roomMap.join().replace(",", "-");
      return roomMapJoin.split("-").filter((e) => e === "A").length;
    },
    totalChildren() {
      const roomMapJoin = this.search.roomMap.join().replace(",", "-");
      return roomMapJoin.split("-").filter((e) => e === "C").length;
    },
    selectedAreaText() {
      if (!this.search.selectedArea.length) return "";
      const text = this.getLabel(
        this.search.selectedArea[this.search.selectedArea.length - 1]?.name
      );
      return text == this.$t("portal.noSelect") ? "" : text;
    },
    hotelName() {
      const hotel = this.portalHotels.find(
        (d) => d.value == this.$route.query.hotelId
      );
      return hotel ? hotel.text : "";
    },
    conditionsLength() {
      return (
        this.search.selectedMeals.length +
        this.search.selectedHotelTypes.length +
        this.search.selectedHotelTags.length +
        this.search.selectedRoomTags.length
      );
    },
  },
};
</script>