<template>
	<v-app-bar
		app
		color="white"
		fixed>
		<template v-if="$route.params.id">
			<router-link
				:to="{
					name: 'PortalSite',
					params: {id: $route.params.id},
					query: query,
				}"
				class="hotel-title"
				:class="$vuetify.breakpoint.xs || 'pl-2'"
				style="text-decoration: none">
				<h1
					class="text-h6 text-sm-h6 text-md-h5 grey--text text--darken-3"
					:class="titleLength > 30 ? 'text-subtitle-1 font-weight-bold' : ''">
					{{ getLabel($root.portalDefaultData.title) || 'PortalSite' }}
				</h1>
			</router-link>
		</template>
		<template v-else>
			<h1
				class="text-h6 text-sm-h6 text-md-h5 grey--text text--darken-3"
				:class="titleLength > 30 ? 'text-subtitle-1 font-weight-bold' : ''">
				{{ getLabel($root.portalDefaultData.title) || 'PortalSite' }}
			</h1>
		</template>
		<v-spacer></v-spacer>
		<LangSelectorForMobile v-if="$vuetify.breakpoint.xs" />
		<LangSelector v-else />
	</v-app-bar>
</template>

<script>
import LangSelector from '@/components/LangSelector.vue';
import LangSelectorForMobile from '@/components/LangSelectorForMobile.vue';
import {cloneDeep} from 'lodash';

export default {
	components: {
		LangSelector,
		LangSelectorForMobile,
	},
	computed: {
		query() {
			let queryData = cloneDeep(this.$route.query);
			return queryData;
		},
		titleLength() {
			if (this.$route.path == '/portal-site-mock') return 0;
			if (!this.$root.portalDefaultData.title) return 0;
			let v = this.getLabel(this.$root.portalDefaultData.title);
			let len = 0;
			for (let i = 0; i < v.length; i++) {
				if (v[i].match(/[ -~]/) ? (len += 1) : (len += 2));
			}
			return len;
		},
	},
};
</script>

<style lang="scss" scoped>
.hotel-title {
	text-decoration: none;
	color: rgba(0, 0, 0, 0.87);
}
</style>
