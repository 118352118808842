<template>
	<v-sheet
		outlined
		rounded
		class="d-flex align-center pl-2"
		style="
			height: 45px;
			border: 1px grey solid;
			background: white;
			width: 100%;
		">
		<v-icon>mdi-map-marker</v-icon>
		<v-select
			v-model="selectedArea[0]"
			:items="areas"
			return-object
			item-value="id"
			item-disabled
			solo
			flat
			dense
			:placeholder="selectedArea[0] ? '' : $t('portal.areaSelect')"
			:label="selectedArea[0] ? $t('portal.area') : ''"
			class="pa-0 ml-1 mb-1"
			style="cursor: pointer"
			:style="styleObject"
			single-line
			hide-details
			@change="selectedArea.splice(1, 2)">
			<template v-slot:selection="data">
				<div
					style="
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					">
					{{ getLabel(data.item.title) }}
				</div>
			</template>
			<template v-slot:item="data">
				<div
					style="
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					">
					{{ getLabel(data.item.title) }}
				</div>
			</template>
		</v-select>
		<!-- 第二階層 -->
		<v-select
			v-if="selectedArea[0]"
			v-model="selectedArea[1]"
			:items="mediumAreas"
			return-object
			item-value="id"
			:placeholder="$t('portal.area2')"
			solo
			flat
			dense
			class="pa-0"
			:style="styleObject2"
			hide-details
			single-line
			:disabled="!selectedArea[0] || !selectedArea[0].id"
			@change="selectedArea.splice(2, 1)">
			<template v-slot:selection="data">
				<div
					style="
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					">
					{{ getLabel(data.item.title) }}
				</div>
			</template>
			<template v-slot:item="data">
				<div
					style="
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					">
					{{ getLabel(data.item.title) }}
				</div>
			</template>
		</v-select>
		<!-- 第三階層 -->
		<v-select
			v-if="isThirdLayerSet && selectedArea[0]"
			v-model="selectedArea[2]"
			:items="smallAreas"
			return-object
			item-value="id"
			:placeholder="$t('portal.area3')"
			solo
			flat
			dense
			class="pa-0"
			:style="styleObject2"
			hide-details
			single-line
			:disabled="!selectedArea[1] || !selectedArea[1].id || !smallAreas.length">
			<template v-slot:selection="data">
				<div
					class="pa-0"
					style="
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					">
					{{ getLabel(data.item.title) }}
				</div>
			</template>
			<template v-slot:item="data">
				<div
					class="pa-0"
					style="
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					">
					{{ getLabel(data.item.title) }}
				</div>
			</template>
		</v-select>
	</v-sheet>
</template>

<script>
export default {
	props: {
		value: {type: Array},
		areas: {type: Array},
		isThirdLayerSet: {type: Boolean},
	},
	data: () => ({
		show: false,
	}),
	computed: {
		selectedArea: {
			get() {
				return this.value;
			},
			set(val) {
				this.$emit('input', val);
			},
		},
		styleObject() {
			if (this.selectedArea[0]) {
				return {
					width: 150 + 'px',
					'font-size': 15 + 'px',
					cursor: 'pointer',
				};
			} else {
				return {
					'font-size': 15 + 'px',
					cursor: 'pointer',
				};
			}
		},
		styleObject2() {
			return {
				width: 150 + 'px',
				'font-size': 15 + 'px',
			};
		},
		mediumAreas() {
			return this.selectedArea[0].childAreas
				? this.selectedArea[0].childAreas.filter(
						d => !d.type || d.type != 'selectAll',
				)
				: [];
		},
		smallAreas() {
			return this.selectedArea[1] &&
				this.selectedArea[1].id &&
				this.selectedArea[1].childAreas
				? this.selectedArea[1].childAreas.filter(
						d => !d.type || d.type != 'selectAll',
				)
				: [];
		},
	},
};
</script>