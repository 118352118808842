<template>
  <v-autocomplete
    v-if="!smallPanel"
    v-model="data"
    :items="items"
    item-value="id"
    :item-text="item => getLabel(item.title)"
    style="background: white"
    :style="{ 'font-size': $vuetify.breakpoint.xs ? 16 + 'px' : 15 + 'px' }"
    outlined
    height="45px"
    dense
    placeholder=" "
    hide-details="auto"
    menu-props="auto"
    :clearable="$vuetify.breakpoint.xs"
    no-filter
    disable-lookup
    :label="$t('portal.facilityNameSearch')"
    :no-data-text="$t('portal.noDataFound')"
  >
    <template v-slot:prepend-inner>
      <v-icon class="mt-1">mdi-office-building</v-icon>
    </template>
  </v-autocomplete>
  <div
    v-else-if="smallPanel && data"
    class="mt-1"
    style="
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 200px;
    "
  >
    {{ hotelName }}
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
    items: { type: Array },
    smallPanel: { type: Boolean, default: false },
  },
  data: () => ({
    isOpen: false,
  }),
  computed: {
    data: {
      get() {
        return !this.value ? "" : this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    hotelName() {
      if (!this.smallPanel) return;
      const hotel = this.items.find((d) => d.value == this.data);
      return hotel ? hotel.text : "";
    },
  },
};
</script>