<template>
	<div
		v-if="!viewLoading"
		class="pb-4">
		<AppBar />
		<v-main class="d-flex">
			<v-img
				src="https://picsum.photos/id/323/3831/2554"
				width="100%"
				:height="$vuetify.breakpoint.xs ? 250 + 'px' : 350 + 'px'"
				class="d-flex align-end"
				:class="[$vuetify.breakpoint.xs || 'pb-5']">
				<v-container
					v-if="!$vuetify.breakpoint.xs"
					class="d-flex justify-center">
					<SearchPanel
						v-model="search"
						:portalHotels="portalHotels"
						:areas="areas"
						:hotelTypes="hotelTypes"
						:hotelTags="hotelTags"
						:roomTags="roomTags"
						:meals="meals"
						:isThirdLayerSet="isThirdLayerSet"
						style="z-index: 1"
						:style="{
							width:
								$vuetify.breakpoint.sm || $vuetify.breakpoint.md
									? 100 + '%'
									: '',
						}" />
				</v-container>
			</v-img>
			<v-container
				v-if="$vuetify.breakpoint.xs"
				class="d-flex align-end"
				:class="!search.smallPanel || 'pa-0'"
				:style="
					search.smallPanel
						? 'z-index: 30; position: sticky; top: 56px'
						: 'z-index: 10'
				">
				<SearchPanelForMobile
					v-model="search"
					:portalHotels="portalHotels"
					:areas="areas"
					:hotelTypes="hotelTypes"
					:hotelTags="hotelTags"
					:roomTags="roomTags"
					:meals="meals"
					:isThirdLayerSet="isThirdLayerSet"
					style="z-index: 1; width: 100%"
					:style="{
						'margin-top': !search.smallPanel ? -100 + 'px' : '',
					}"
					@small-panel="search.smallPanel = $event" />
			</v-container>
			<v-container
				:class="$vuetify.breakpoint.xs && search.smallPanel ? 'pt-6' : 'pt-4'"
				:style="{
					width:
						$vuetify.breakpoint.xs || $vuetify.breakpoint.sm
							? 100 + '%'
							: 1200 + 'px',
				}">
				<section
					:class="$vuetify.breakpoint.xs && search.smallPanel ? '' : 'mt-4'">
					<v-alert
						v-if="isChildSelected"
						dense
						type="info"
						outlined
						class="text-body-2 mt-2 mb-4">
						{{ $t('portal.childNotice') }}
					</v-alert>
					<header class="font-weight-bold">
						{{ $t('portal.facilityList') }}
						<span class="ml-2 font-weight-regular text-body-2">{{
							$t('portal.isReferencePrice')
						}}</span>
					</header>
					<div class="mt-4">
						<div
							v-if="searchLoading"
							class="text-center">
							<v-progress-circular
								:width="3"
								color="primary"
								indeterminate></v-progress-circular>
						</div>
						<div v-else>
							<v-row v-if="hotels.length">
								<v-col
									v-for="(hotel, i) in hotels"
									:key="i"
									xl="4"
									lg="4"
									md="6"
									sm="6"
									cols="12">
									<MockHotel :hotel="hotel" />
								</v-col>
							</v-row>
							<div
								v-else
								class="text-center mt-10 grey--text text--darken-2">
								{{ $t('portal.noPlansFound') }}
							</div>
						</div>
					</div>
				</section>
			</v-container>
		</v-main>
	</div>
</template>

<script>
import AppBar from './PortalSite/AppBar';
import MockHotel from './PortalSite/MockHotel.vue';
import SearchPanel from './PortalSite/SearchPanel.vue';
import SearchPanelForMobile from './PortalSite/SearchPanelForMobile.vue';

import {data, meals} from '../mockData/data.json';
import {cloneDeep} from 'lodash';

export default {
	components: {
		AppBar,
		MockHotel,
		SearchPanel,
		SearchPanelForMobile,
	},
	props: {
		portalHotels: Array,
		areas: Array,
		areasAll: Array,
		hotelTypes: Array,
		hotelTags: Array,
		roomTags: Array,
	},
	data: () => ({
		search: {
			checkedInOn: '',
			checkedOutOn: '',
			night: null,
			roomMap: null,
			hotelId: '',
			promotionCode: '',
			selectedArea: [],
			selectedHotelTypes: [],
			selectedHotelTags: [],
			selectedRoomTags: [],
			selectedMeals: [],
			smallPanel: false,
		},
		data: data,
		hotels: [],
		meals: meals,
		searchLoading: false,
		isThirdLayerSet: false,
		isChildSelected: false,
	}),
	async created() {
		this.viewLoading = true;
		await this.setHotels();
		this.syncQuery();
		this.scrollTop();
		this.dataReady = true;
		this.viewLoading = false;
	},
	methods: {
		async setHotels() {
			let data = cloneDeep(this.data);
			data = await Promise.all(
				data
					.filter(d => {
						let isMatch = [true, true, true];
						const selectedArea = this.$route.query.areaIds;
						const hotelId = this.$route.query.hotelId;
						const hotelTypes = this.$route.query.hotelTypeIds
							? this.$route.query.hotelTypeIds.split(',')
							: [];
						if (selectedArea) this.areaFilter(d) || isMatch.pop();
						if (hotelId) d.hotelId == hotelId || isMatch.pop();
						if (hotelTypes.length)
							hotelTypes.includes(d.hotelTypeId) || isMatch.pop();
						return isMatch.length === 3;
					})
					.map(async hotel => {
						const areas = hotel.areas.filter(area => !area.parentId);
						const areaNames = await Promise.all(
							areas.map(area => {
								const childAreas = hotel.areas.filter(
									child => child.parentId === area.id,
								);
								if (!childAreas.length) return this.getLabel(area.title);
								return childAreas.map(childArea => {
									return `${this.getLabel(area.title)} ${this.getLabel(
										childArea.title,
									)}`;
								});
							}),
						);
						return {
							...hotel,
							hotelType: hotel.hotelType || {ja: 'その他', en: 'Other'},
							areaNames: areaNames,
							plan_count: hotel.matchedPlanInfo.planCount,
							min_price: hotel.matchedPlanInfo.priceMin,
							max_price: hotel.matchedPlanInfo.priceMax,
							hotel_image: hotel.image.url,
							special_code_flg: hotel.matchedPlanInfo.isSecret,
						};
					}),
			);
			this.hotels.splice(0);
			this.hotels.push(...data);
		},
		areaFilter(d) {
			let selectedArea = this.$route.query.areaIds;
			if (!selectedArea) return true;
			const selectedAreaArr = selectedArea.split(',');
      return d.areas.some(area => selectedAreaArr.includes(area.id));
		},
		syncQuery() {
			let childCount = null;
			let roomMap = cloneDeep(this.$route.query.roomMap).split(',');
			roomMap.map(d => (d.includes('C') ? childCount++ : ''));
			this.isChildSelected = childCount > 0 ? true : false;

			Object.keys(this.$route.query).map(key => {
				switch (key) {
					case 'roomMap':
						this.search.roomMap = this.$route.query.roomMap ? roomMap : [];
						break;
					case 'portalSiteSpecialCode':
						this.search.promotionCode = this.$route.query.portalSiteSpecialCode;
						break;
					case 'areaIds': {
						let areaIdsArr = this.$route.query.areaIds ? this.$route.query.areaIds.split(',') : [];
						if (areaIdsArr && areaIdsArr.length) {
              this.search.selectedArea = areaIdsArr.map(areaId => {
								const area = this.areasAll.find(d => d.id == areaId);
								return {
                  id: area.id,
									title: area.title,
									childAreas: area.areas || [],
								};
							});
            } else {
              this.search.selectedArea = [];
            }
						break;
					}
					case 'hotelTypeIds':
						this.search.selectedHotelTypes = this.$route.query.hotelTypeIds
							? this.$route.query.hotelTypeIds.split(',')
							: [];
						break;
					case 'hotelTagIds':
						this.search.selectedHotelTags = this.$route.query.hotelTagIds
							? this.$route.query.hotelTagIds.split(',')
							: [];
						break;
					case 'roomTagIds':
						this.search.selectedRoomTags = this.$route.query.roomTagIds
							? this.$route.query.roomTagIds.split(',')
							: [];
						break;
					case 'meal':
						this.search.selectedMeals = this.$route.query.meal
							? this.$route.query.meal.split(',')
							: [];
						break;
					case 'smallPanel':
						this.search.smallPanel = JSON.parse(this.$route.query.smallPanel);
						break;
					default:
						this.search[key] = this.$route.query[key];
						break;
				}
			});
		},
		scrollTop() {
			window.scrollTo({
				top: 0,
				behavior: 'instant',
			});
		},
	},
};
</script>

<style>
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
	> .v-input__control
	> .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
	padding: 0 10px 0 10px !important;
}
</style>