var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.viewLoading)?_c('div',{staticClass:"pb-4"},[_c('AppBar'),_c('v-main',{staticClass:"d-flex"},[_c('v-img',{staticClass:"d-flex align-end",class:[_vm.$vuetify.breakpoint.xs || 'pb-5'],attrs:{"src":"https://picsum.photos/id/323/3831/2554","width":"100%","height":_vm.$vuetify.breakpoint.xs ? 250 + 'px' : 350 + 'px'}},[(!_vm.$vuetify.breakpoint.xs)?_c('v-container',{staticClass:"d-flex justify-center"},[_c('SearchPanel',{staticStyle:{"z-index":"1"},style:({
						width:
							_vm.$vuetify.breakpoint.sm || _vm.$vuetify.breakpoint.md
								? 100 + '%'
								: '',
					}),attrs:{"portalHotels":_vm.portalHotels,"areas":_vm.areas,"hotelTypes":_vm.hotelTypes,"hotelTags":_vm.hotelTags,"roomTags":_vm.roomTags,"meals":_vm.meals,"isThirdLayerSet":_vm.isThirdLayerSet},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e()],1),(_vm.$vuetify.breakpoint.xs)?_c('v-container',{staticClass:"d-flex align-end",class:!_vm.search.smallPanel || 'pa-0',style:(_vm.search.smallPanel
					? 'z-index: 30; position: sticky; top: 56px'
					: 'z-index: 10')},[_c('SearchPanelForMobile',{staticStyle:{"z-index":"1","width":"100%"},style:({
					'margin-top': !_vm.search.smallPanel ? -100 + 'px' : '',
				}),attrs:{"portalHotels":_vm.portalHotels,"areas":_vm.areas,"hotelTypes":_vm.hotelTypes,"hotelTags":_vm.hotelTags,"roomTags":_vm.roomTags,"meals":_vm.meals,"isThirdLayerSet":_vm.isThirdLayerSet},on:{"small-panel":function($event){_vm.search.smallPanel = $event}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1):_vm._e(),_c('v-container',{class:_vm.$vuetify.breakpoint.xs && _vm.search.smallPanel ? 'pt-6' : 'pt-4',style:({
				width:
					_vm.$vuetify.breakpoint.xs || _vm.$vuetify.breakpoint.sm
						? 100 + '%'
						: 1200 + 'px',
			})},[_c('section',{class:_vm.$vuetify.breakpoint.xs && _vm.search.smallPanel ? '' : 'mt-4'},[(_vm.isChildSelected)?_c('v-alert',{staticClass:"text-body-2 mt-2 mb-4",attrs:{"dense":"","type":"info","outlined":""}},[_vm._v(" "+_vm._s(_vm.$t('portal.childNotice'))+" ")]):_vm._e(),_c('header',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t('portal.facilityList'))+" "),_c('span',{staticClass:"ml-2 font-weight-regular text-body-2"},[_vm._v(_vm._s(_vm.$t('portal.isReferencePrice')))])]),_c('div',{staticClass:"mt-4"},[(_vm.searchLoading)?_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"width":3,"color":"primary","indeterminate":""}})],1):_c('div',[(_vm.hotels.length)?_c('v-row',_vm._l((_vm.hotels),function(hotel,i){return _c('v-col',{key:i,attrs:{"xl":"4","lg":"4","md":"6","sm":"6","cols":"12"}},[_c('MockHotel',{attrs:{"hotel":hotel}})],1)}),1):_c('div',{staticClass:"text-center mt-10 grey--text text--darken-2"},[_vm._v(" "+_vm._s(_vm.$t('portal.noPlansFound'))+" ")])],1)])],1)])],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }