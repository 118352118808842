<template>
  <div>
    <!-- モバイル -->
    <template v-if="$vuetify.breakpoint.xs">
      <div v-if="!smallPanel">
        <v-text-field
          :value="formatDate(date)"
          style="background: white; font-size: 16px; cursor: pointer"
          height="45px"
          dense
          outlined
          hide-details="auto"
          :disabled="disabled"
          :rules="rules"
          readonly
          @click="modalShow('datePickerModal')"
        >
          <template v-slot:prepend-inner>
            <v-icon class="mt-1" style="padding-right: 2px">
              mdi-calendar
            </v-icon>
          </template>
          <template v-slot:label>
            <span class="pl-1">{{ $t("portal.lengthOfStay") }}</span>
          </template>
          <template v-slot:append>
            <div
              class="mt-2 text-no-wrap"
              :style="{ 'max-width': isDayUse ? 80 + 'px' : '' }"
            >
              {{ lengthOfStay }}
            </div>
          </template>
        </v-text-field>
        <frame-modal :name="'datePickerModal'" fullscreen>
          <v-card class="d-flex flex-column">
            <v-sheet color="#42A5F5" tile dark class="pa-2 font-weight-bold">
              <div class="text-right py-2 px-1">
                <v-btn text icon small color="white" @click="modalClose()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <div class="d-flex justify-center">
                <div v-if="isDayUse">
                  <div class="text-center font-weight-medium">
                    {{ $t("common.dayuse") }}
                  </div>
                  <div class="text-h6 font-weight-bold" style="height: 2em">
                    {{ checkInPicked }}
                  </div>
                </div>
                <div v-else class="d-flex align-center">
                  <div class="text-center font-weight-medium">
                    {{ $t("common.checkIn") }}
                    <div class="text-h6 font-weight-bold" style="height: 2em">
                      {{ checkInPicked }}
                    </div>
                  </div>
                  <div class="mx-6"><v-icon>mdi-arrow-right</v-icon></div>
                  <div class="text-center font-weight-medium">
                    {{ $t("common.checkOut") }}
                    <div class="text-h6 font-weight-bold" style="height: 2em">
                      {{ checkOutPicked || " " }}
                    </div>
                  </div>
                </div>
              </div>
            </v-sheet>
            <v-date-picker
              v-model="date"
              :day-format="(v) => new Date(v).getDate()"
              :min="minDate"
              :locale="lang"
              range
              no-title
              class="mt-4"
              full-width
            />
            <div class="d-flex justify-end pr-4">
              <v-checkbox
                v-model="isDayUse"
                hide-details="auto"
                class="mb-5 mt-0"
                @change="
                  isDayUse ? (date[1] = date[0]) : (date[1] = '');
                  $emit('input', date);
                "
              >
                <template v-slot:label>
                  <div class="text-subtitle-1 grey--text text--darken-3">
                    {{ $t("common.dayuse") }}
                  </div>
                </template>
              </v-checkbox>
            </div>
            <div class="px-2 mt-auto">
              <v-btn
                color="secondary"
                class="white--text font-weight-bold mb-4"
                style="width: 100%"
                large
                @click="selectDate"
              >
                {{ $t("portal.decide") }}
              </v-btn>
            </div>
          </v-card>
        </frame-modal>
      </div>
      <div v-else class="my-0 py-0">
        {{ formatDate(date) }}
      </div>
    </template>

    <!-- タブレット・PC -->
    <v-menu
      v-else
      ref="menu"
      :value="show"
      :close-on-content-click="false"
      transition="scale-transition"
      :return-value="value"
      class="pr-1"
      offset-y
      min-width="290px"
      :disabled="disabled"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          :value="formatDate(date)"
          v-on="on"
          v-bind="attrs"
          style="background: white; font-size: 1.1em; cursor: pointer"
          height="45px"
          dense
          prepend-inner-icon="mdi-calendar"
          outlined
          hide-details="auto"
          :disabled="disabled"
          :rules="rules"
          readonly
          @click="show = true"
        >
          <template v-slot:prepend-inner>
            <v-icon class="mt-1 mr-1"> mdi-calendar </v-icon>
          </template>
          <template v-slot:label>
            <span class="pl-1">{{ $t("portal.lengthOfStay") }}</span>
          </template>
          <template v-slot:append>
            <div
              class="mt-2 text-no-wrap"
              :style="{ 'max-width': isDayUse ? 75 + 'px' : '' }"
            >
              {{ lengthOfStay }}
            </div>
          </template>
        </v-text-field>
      </template>
      <v-date-picker
        v-model="date"
        :day-format="(v) => new Date(v).getDate()"
        :min="minDate"
        :locale="lang"
        range
        no-title
      >
        <div class="d-flex flex-column" style="width: 100%">
          <div class="d-flex justify-end pr-2">
            <v-checkbox
              v-model="isDayUse"
              hide-details="auto"
              class="mb-5 mt-0"
              dense
              @change="
                isDayUse ? (date[1] = date[0]) : (date[1] = '');
                $emit('input', date);
              "
            >
              <template v-slot:label>
                <div class="text-subtitle-2 grey--text text--darken-3">
                  {{ $t("common.dayuse") }}
                </div>
              </template>
            </v-checkbox>
          </div>
          <div class="px-2">
            <v-btn
              color="secondary"
              class="white--text font-weight-bold mb-4"
              style="width: 100%"
              @click="selectDate"
            >
              {{ $t("portal.decide") }}
            </v-btn>
          </div>
        </div>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import FrameModal from "@/components/FrameModal.vue";
import dayjs from "dayjs";

export default {
  components: {
    FrameModal,
  },
  props: {
    value: { type: Array },
    rules: { type: Array, default: () => [] },
    label: { type: String, default: "" },
    disabled: { type: Boolean, default: false },
    min: { type: String, default: "" },
    smallPanel: { type: Boolean, default: false },
  },
  data: () => ({
    show: false,
    isDayUse: false,
    diff: null,
  }),
  watch: {
    async date() {
      await this.setRange();
    },
  },
  computed: {
    date: {
      get() {
        return this.value;
      },
      set(val) {
        if (!val[0] && !val[1]) return;
        if (this.isDayUse) {
          val[1] = val[0];
        } else {
          if (val[0] == val[1]) this.isDayUse = true;
          else this.isDayUse = false;
          // チェックインとチェックアウトの日程が逆転されるのを防ぐ
          if (val[0] > val[1]) {
            val[0] = val[1];
            val = [val[0]];
          }
        }
        this.$emit("input", val);
      },
    },
    start() {
      return dayjs(this.date).startOf("month").format("YYYY-MM-DD");
    },
    end() {
      return dayjs(this.date).endOf("month").format("YYYY-MM-DD");
    },
    minDate() {
      return dayjs().format("YYYY-MM-DD");
    },
    lengthOfStay() {
      if (isNaN(this.diff)) return "";
      switch (this.diff) {
        case 0:
          return this.$t("common.dayuse");
        case 1:
          return this.$t("portal.nightCount", { nights: this.diff });
        default:
          return this.$t("portal.nightsCount", { nights: this.diff });
      }
    },
    checkInPicked() {
      return this.date && this.date[0]
        ? dayjs(this.date[0]).format("YYYY/MM/DD")
        : "";
    },
    checkOutPicked() {
      return this.date && this.date[1]
        ? dayjs(this.date[1]).format("YYYY/MM/DD")
        : "";
    },
  },
  methods: {
    formatDate(date) {
      const checkIn =
        date && date[0]
          ? dayjs(date[0]).format("YYYY/MM/DD")
          : this.$t("common.checkIn");
      const checkOut =
        date && date[1]
          ? dayjs(date[1]).format("YYYY/MM/DD")
          : this.$t("common.checkOut");
      this.diff = dayjs(checkOut).clone().diff(checkIn, "days");
      return checkIn == checkOut ? checkIn : `${checkIn} - ${checkOut}`;
    },
    setRange() {
      if (!this.date[0] && !this.date[1]) return;
      if (this.isDayUse) {
        this.date[1] = this.date[0];
      } else {
        if (this.date[0] == this.date[1]) this.isDayUse = true;
        else this.isDayUse = false;
        // チェックインとチェックアウトの日程が逆転されるのを防ぐ
        if (this.date[0] > this.date[1]) {
          this.date[0] = this.date[1];
          this.date = [this.date[0]];
        }
      }
      return;
    },
    selectDate() {
      const searchNight = this.portalDefaultSetting?.defaultCondition?.nights || 1
      if (!this.date[1]) {
        this.date[1] = dayjs(this.date[0]).add(searchNight, "day").format("YYYY-MM-DD");
      }
      this.show = false;
      this.formatDate(this.date);
      this.modalClose();
    },
  },
};
</script>