import { cloneDeep } from "lodash";

export default {
  computed: {
    checkedInOn: {
      get() {
        return this.query_getValue("checkedInOn");
      },
    },
    checkedOutOn: {
      get() {
        return this.query_getValue("checkedOutOn");
      },
    },
    night: {
      get() {
        return this.query_getIntegerValue("night");
      },
    },
    roomMap: {
      get() {
        return this.query_getArrayValue("roomMap").map((str) => str.split(","));
      },
    },
    meal: {
      get() {
        return this.query_getValue("meal");
      },
    },
    hotelId: {
      get() {
        return this.query_getValue("hotelId");
      },
    },
    portalSiteSpecialCode: {
      get() {
        return this.query_getValue("portalSiteSpecialCode");
      },
    },
    areaIds: {
      get() {
        return this.query_getValue("areaIds");
      },
    },
    hotelTypeIds: {
      get() {
        return this.query_getValue("hotelTypeIds");
      },
    },
    hotelTagIds: {
      get() {
        return this.query_getValue("hotelTagIds");
      },
    },
    roomTagIds: {
      get() {
        return this.query_getValue("roomTagIds");
      },
    },
    query: {
      get() {
        const query = {
          checkedInOn: this.checkedInOn,
          checkedOutOn: this.checkedOutOn,
          night: this.night,
          roomMap: this.roomMap.join(","),
          meal: this.meal,
          hotelId: this.hotelId,
          portalSiteSpecialCode: this.portalSiteSpecialCode,
          areaIds: this.areaIds,
          hotelTypeIds: this.hotelTypeIds,
          hotelTagIds: this.hotelTagIds,
          roomTagIds: this.roomTagIds,
        };
        Object.keys(query).map((k) => {
          if (query[k] == null || query.length == 0) {
            delete query[k];
          }
        });
        return query;
      },
    },
  },
  methods: {
    //SingleValue
    query_getValue(key) {
      return this.$route.query[key] ? this.$route.query[key] : null;
    },
    query_setValue(key, value) {
      let query = cloneDeep(this.$route.query);
      if (value) {
        query[key] = value;
      } else {
        delete query[key];
      }
      this.$router.replace({ query: query }).catch(() => {});
    },
    query_getIntegerValue(key) {
      return this.$route.query[key] ? parseInt(this.$route.query[key]) : null;
    },
    //Boolean
    query_getBooleanValue(key) {
      return !!this.$route.query[key];
    },
    query_setBooleanValue(key, value) {
      let query = cloneDeep(this.$route.query);
      if (value) {
        query[key] = true;
      } else {
        delete query[key];
      }
      this.$router.replace({ query: query }).catch(() => {});
    },
    //Array
    query_getArrayValue(key) {
      return this.$route.query[key] ? this.$route.query[key].split(",") : [];
    },
    query_setArrayValue(key, values) {
      let query = cloneDeep(this.$route.query);
      if (values.length) {
        query[key] = values.join(",");
      } else {
        delete query[key];
      }
      this.$router.replace({ query: query }).catch(() => {});
    },
  },
};
