<template>
  <v-text-field v-if="!smallPanel" v-model="data" style="background: white"
    :style="{ 'font-size': $vuetify.breakpoint.xs ? 16 + 'px' : 15 + 'px' }" outlined height="45px" dense placeholder=" "
    hide-details="auto" clearable>
    <template v-slot:prepend-inner>
      <v-icon class="mt-1">{{ icon }}</v-icon>
    </template>
    <template v-slot:label>
      <span class="pl-1">
        {{ label }}
      </span>
    </template>
  </v-text-field>
  <div v-else-if="smallPanel && value" class="d-flex align-center">
    <span>{{ `${label}: ${data}` }}</span>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String },
    type: { type: String, required: true },
    smallPanel: { type: Boolean, default: false },
  },
  computed: {
    data: {
      get() {
        return !this.value ? " " : this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    icon() {
      if (this.type == "promotionCode") return "mdi-code-tags";
      else return "";
    },
    label() {
      if (this.type == "promotionCode") return this.$t("portal.promotionCode");
      else return "";
    },
  },
};
</script>