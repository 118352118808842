<template>
  <div>
    <!-- モバイル -->
    <template v-if="$vuetify.breakpoint.xs">
      <!-- <v-btn
        v-if="smallPanel"
        small
        rounded
        label
        color="white"
        @click="modalShow('filterSelectorModal')"
      >
        <v-icon small color="grey darken-2">mdi-filter-plus-outline</v-icon>
        <v-badge color="green" dot x-small>
          <span class="text-subtitle-2 ml-1 grey--text text--darken-3">{{
            $t("plans.toolBar.filterConditions")
          }}</span>
        </v-badge>
      </v-btn> -->
      <v-btn
        text
        class="text-subtitle-1"
        color="primary"
        small
        style="height: 40px"
        @click="modalShow('filterSelectorModal')"
      >
        <template v-if="conditionsLength > 0">
          <v-badge color="green" :content="conditionsLength">
            <v-icon>mdi-filter-plus-outline</v-icon
            >{{ $t("plans.toolBar.filterConditions") }}
          </v-badge>
        </template>
        <template v-else>
          <v-icon>mdi-filter-plus-outline</v-icon
          >{{ $t("plans.toolBar.filterConditions") }}
        </template>
      </v-btn>

      <frame-modal :name="'filterSelectorModal'" fullscreen>
        <v-card
          class="search-panel-room-selector"
          style="width: 400px; height: 100%"
        >
          <div class="text-right py-3 px-2" style="height: 5%">
            <v-btn text icon small color="black" @click="modalClose()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-card-text class="pa-5" style="overflow: scroll; height: 85%">
            <!-- 食事条件 -->
            <div>
              <div
                class="mb-1 grey--text text--darken-3"
                style="font-size: 1.1em; font-weight: 600"
              >
                {{ $t("plans.toolBar.meal") }}
              </div>
              <div class="d-flex flex-wrap" style="word-break: break-all">
                <v-checkbox
                  v-model="search.selectedMeals"
                  v-for="(meal, i) in meals"
                  :key="i"
                  :value="meal.value"
                  dense
                  hide-details="auto"
                  class="ma-0 mr-3"
                >
                  <template v-slot:label>
                    <span style="font-size: 16px">{{
                      getLabel(meal.label)
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
            </div>
            <!-- 施設タイプ -->
            <div v-if="hotelTypes.length">
              <v-divider class="my-3"></v-divider>
              <div
                class="mb-1 grey--text text--darken-3"
                style="font-size: 1.1em; font-weight: 600"
              >
                {{ $t("portal.facilityType") }}
              </div>
              <div class="d-flex flex-wrap" style="word-break: break-all">
                <v-checkbox
                  v-model="search.selectedHotelTypes"
                  v-for="(hotelType, i) in hotelTypes"
                  :key="i"
                  :value="hotelType.id"
                  dense
                  hide-details="auto"
                  class="ma-0 mr-3"
                >
                  <template v-slot:label>
                    <span style="font-size: 16px">{{
                      getLabel(hotelType.title)
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
            </div>

            <!-- 設備タグ -->
            <div v-if="hotelTags.length">
              <v-divider class="my-3"></v-divider>
              <div
                class="mb-1 grey--text text--darken-3"
                style="font-size: 1.1em; font-weight: 600"
              >
                {{ $t("portal.conditions") }}
              </div>
              <div
                class="mt-2 mb-1 grey--text text--darken-3"
                style="font-size: 1.1em"
              >
                {{ $t("portal.hotel") }}
              </div>
              <div class="d-flex flex-wrap" style="word-break: break-all">
                <v-checkbox
                  v-model="search.selectedHotelTags"
                  v-for="(hotelTag, i) in hotelTags"
                  :key="i"
                  :value="hotelTag.id"
                  dense
                  hide-details="auto"
                  class="ma-0 mr-3"
                >
                  <template v-slot:label>
                    <span style="font-size: 16px">{{
                      getLabel(hotelTag.title)
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
            </div>

            <!-- 部屋タグ -->
            <div v-if="roomTags.length" class="mt-3">
              <div
                class="mb-1 grey--text text--darken-3"
                style="font-size: 1.1em"
              >
                {{ $t("portal.room") }}
              </div>
              <div class="d-flex flex-wrap" style="word-break: break-all">
                <v-checkbox
                  v-model="search.selectedRoomTags"
                  v-for="(roomTag, i) in roomTags"
                  :key="i"
                  :value="roomTag.id"
                  dense
                  hide-details="auto"
                  class="ma-0 mr-3"
                >
                  <template v-slot:label>
                    <span style="font-size: 16px">{{
                      getLabel(roomTag.title)
                    }}</span>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </v-card-text>
          <v-card-actions class="pt-0 pb-3 mr-1" style="height: 10%">
            <div class="d-flex align-center" style="width: 100%">
              <v-btn
                outlined
                elevation="2"
                color="grey"
                class="grey--text text--darken-3 font-weight-bold mr-2"
                style="width: 30%"
                large
                @click="reset()"
              >
                {{ $t("plans.toolBar.clear") }}
              </v-btn>
              <v-btn
                color="secondary"
                class="white--text font-weight-bold"
                style="width: 70%"
                large
                @click="modalClose()"
              >
                {{ $t("portal.decide") }}
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </frame-modal>
    </template>

    <!-- タブレット・PC -->
    <v-menu
      v-else
      v-model="menu"
      offset-y
      transition="scale-transition"
      persistent
      style="position: absolute; right: 0"
      :close-on-content-click="false"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="mr-4">
          <v-btn
            v-bind="attrs"
            v-on="on"
            text
            class="text-subtitle-1"
            color="primary"
            small
            :style="{ height: $vuetify.breakpoint.xs ? 40 + 'px' : 45 + 'px' }"
          >
            <template v-if="conditionsLength > 0">
              <v-badge color="green" x-small :content="conditionsLength">
                <v-icon>mdi-filter-plus-outline</v-icon
                >{{ $t("plans.toolBar.filterConditions") }}
              </v-badge>
            </template>
            <template v-else>
              <v-icon>mdi-filter-plus-outline</v-icon
              >{{ $t("plans.toolBar.filterConditions") }}
            </template>
          </v-btn>
        </div>
      </template>

      <v-card class="search-panel-room-selector" style="width: 400px">
        <v-card-text class="pa-5">
          <!-- 食事条件 -->
          <div>
            <div
              class="mb-1 grey--text text--darken-3"
              style="font-size: 1.1em; font-weight: 600"
            >
              {{ $t("plans.toolBar.meal") }}
            </div>
            <div class="d-flex flex-wrap" style="word-break: break-all">
              <v-checkbox
                v-model="search.selectedMeals"
                v-for="(meal, i) in meals"
                :key="i"
                :value="meal.value"
                dense
                hide-details="auto"
                class="ma-0 mr-3"
              >
                <template v-slot:label>
                  <span style="font-size: 0.9em">{{
                    getLabel(meal.label)
                  }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>
          <!-- 施設タイプ -->
          <div v-if="hotelTypes.length">
            <v-divider class="my-3"></v-divider>
            <div
              class="mb-1 grey--text text--darken-3"
              style="font-size: 1.1em; font-weight: 600"
            >
              {{ $t("portal.facilityType") }}
            </div>
            <div class="d-flex flex-wrap" style="word-break: break-all">
              <v-checkbox
                v-model="search.selectedHotelTypes"
                v-for="(hotelType, k) in hotelTypes"
                :key="k"
                :value="hotelType.id"
                dense
                hide-details="auto"
                class="ma-0 mr-3"
              >
                <template v-slot:label>
                  <span style="font-size: 0.9em">{{
                    getLabel(hotelType.title)
                  }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>

          <!-- 施設タグ -->
          <div v-if="hotelTags.length">
            <v-divider class="my-3"></v-divider>
            <div
              class="mb-1 grey--text text--darken-3"
              style="font-size: 1.1em; font-weight: 600"
            >
              {{ $t("portal.conditions") }}
            </div>
            <div
              class="mt-2 mb-1 grey--text text--darken-3"
              style="font-size: 1.1em"
            >
              {{ $t("portal.hotel") }}
            </div>
            <div class="d-flex flex-wrap" style="word-break: break-all">
              <v-checkbox
                v-model="search.selectedHotelTags"
                v-for="(hotelTag, i) in hotelTags"
                :key="i"
                :value="hotelTag.id"
                dense
                hide-details="auto"
                class="ma-0 mr-3"
              >
                <template v-slot:label>
                  <span style="font-size: 0.9em">{{
                    getLabel(hotelTag.title)
                  }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>

          <!-- 部屋タグ -->
          <div v-if="roomTags.length" class="mt-3">
            <div
              class="mb-1 grey--text text--darken-3"
              style="font-size: 1.1em"
            >
              {{ $t("portal.room") }}
            </div>
            <div class="d-flex flex-wrap" style="word-break: break-all">
              <v-checkbox
                v-model="search.selectedRoomTags"
                v-for="(roomTag, i) in roomTags"
                :key="i"
                :value="roomTag.id"
                dense
                hide-details="auto"
                class="ma-0 mr-3"
              >
                <template v-slot:label>
                  <span style="font-size: 0.9em">{{
                    getLabel(roomTag.title)
                  }}</span>
                </template>
              </v-checkbox>
            </div>
          </div>

          <div class="text-center">
            <v-btn
              rounded
              outlined
              small
              class="mt-8 mb-4 text-subtitle-2"
              @click="reset()"
            >
              {{ $t("plans.toolBar.clear") }}
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions class="pt-0 px-4 pb-6" style="height: 50px">
          <v-btn
            color="secondary"
            class="white--text font-weight-bold"
            style="width: 100%"
            @click="menu = false"
          >
            {{ $t("portal.decide") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import FrameModal from "@/components/FrameModal.vue";

export default {
  components: {
    FrameModal,
  },
  data() {
    return {
      menu: false,
    };
  },
  props: {
    value: { type: Object },
    meals: { type: Array },
    hotelTypes: { type: Array },
		hotelTags: { type: Array },
    roomTags: { type: Array },
    smallPanel: { type: Boolean, default: false },
  },
  computed: {
    search: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    conditionsLength() {
      return (
        this.search.selectedMeals.length +
        this.search.selectedHotelTypes.length +
        this.search.selectedHotelTags.length +
        this.search.selectedRoomTags.length
      );
    },
  },
  methods: {
    reset() {
      this.search.selectedMeals = [];
      this.search.selectedHotelTypes = [];
      this.search.selectedHotelTags = [];
      this.search.selectedRoomTags = [];
    },
  },
};
</script>

<style lang="scss" scoped>
#app .search-panel-room-selector input {
  font-size: 16px !important;
}
</style>
