<template>
  <div>
    <!-- モバイル -->
    <template v-if="$vuetify.breakpoint.xs">
      <div v-if="!smallPanel">
        <v-text-field
          :value="
            $t('plans.searchPanelRoomMapSelector.adultChildRoomNum', {
              totalAdults: totalAdults,
              totalChildren: totalChildren,
              totalRoom: form.rooms.length,
            })
          "
          style="font-size: 1.1em; background: white"
          height="45px"
          outlined
          dense
          readonly
          hide-details="auto"
          @click="modalShow('roomMapSelectorModal')"
        >
          <template v-slot:prepend-inner>
            <v-icon class="mt-1"> mdi-account </v-icon>
          </template>
          <template v-slot:label>
            <span class="pl-1">{{
              $t("plans.searchPanelRoomMapSelector.personRoom")
            }}</span>
          </template>
        </v-text-field>
        <frame-modal :name="'roomMapSelectorModal'" fullscreen>
          <v-form
            ref="form"
            v-model="valid"
            :disabled="form.loading"
            @submit.prevent="
              apply();
              modalClose();
            "
          >
            <v-card
              id="mobile-fullscreen-panel"
              class="pt-2"
              style="height: 100%"
            >
              <div class="text-right py-1 px-2" style="height: 5%">
                <v-btn text icon small color="black" @click="modalClose()">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>
              <v-card-text style="overflow: scroll; height: 85%">
                <v-sheet v-for="(r, i) in form.rooms" :key="i">
                  <div class="d-flex align-center">
                    <v-chip>
                      {{ $t("plans.searchPanelRoomMapSelector.room")
                      }}{{ i + 1 }}
                    </v-chip>
                    <v-btn
                      v-if="i !== 0"
                      icon
                      color="red"
                      @click="removeRoom(i)"
                    >
                      <v-icon color="red">mdi-delete</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex align-center justify-end">
                    <v-btn
                      icon
                      color="light-blue darken-1"
                      class="mx-1"
                      :disabled="r.numberOfAdults == 1"
                      large
                      @click="r.numberOfAdults--"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <span class="text-subtitle-1">{{
                      $t("portal.adult")
                    }}</span>
                    <div
                      class="text-h5 font-weight-medium mx-1 text-center"
                      style="width: 20px"
                    >
                      {{ r.numberOfAdults }}
                    </div>
                    <span class="text-subtitle-1">{{ $t("portal.pax") }}</span>
                    <v-btn
                      icon
                      color="light-blue darken-1"
                      class="mx-1"
                      :disabled="
                        !!maxAdultPerRoom && r.numberOfAdults == maxAdultPerRoom
                      "
                      large
                      @click="r.numberOfAdults++"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <div class="d-flex align-center justify-end">
                    <v-btn
                      icon
                      color="light-blue darken-1"
                      class="mx-1"
                      :disabled="r.numberOfChildren == 0"
                      large
                      @click="r.numberOfChildren--"
                    >
                      <v-icon>mdi-minus</v-icon>
                    </v-btn>
                    <div class="d-flex align-center">
                      <span class="text-subtitle-1">{{
                        $t("portal.child")
                      }}</span>
                      <div
                        class="text-h5 font-weight-medium mx-1 text-center"
                        style="width: 20px"
                      >
                        {{ r.numberOfChildren }}
                      </div>
                      <span class="text-subtitle-1">{{
                        $t("portal.pax")
                      }}</span>
                    </div>
                    <v-btn
                      icon
                      color="light-blue darken-1"
                      class="mx-1"
                      :disabled="
                        !!maxChildPerRoom &&
                        r.numberOfChildren == maxChildPerRoom
                      "
                      large
                      @click="r.numberOfChildren++"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                  <v-divider class="my-3"></v-divider>
                </v-sheet>
                <!-- 部屋追加 -->
                <div class="text-right">
                  <v-btn
                    v-if="
                      !maxRoomsPerBooking ||
                      form.rooms.length < maxRoomsPerBooking
                    "
                    color="secondary"
                    text
                    data-test="add-room"
                    @click="addRoom"
                  >
                    <v-icon class="mr-1">mdi-plus-circle</v-icon>
                    <span class="text-subtitle-1">{{
                      $t("plans.searchPanelRoomMapSelector.addRoom")
                    }}</span>
                  </v-btn>
                </div>
              </v-card-text>
              <v-card-actions class="pt-0 pb-3 mx-2" style="height: 10%">
                <v-btn
                  color="secondary"
                  class="white--text font-weight-bold"
                  style="width: 100%"
                  large
                  @click="modalClose()"
                >
                  {{ $t("portal.decide") }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-form>
        </frame-modal>
      </div>
      <div v-else class="d-flex align-center font-weight-bold">
        <v-icon small color="grey darken-4">mdi-account</v-icon>
        <span>{{
          totalAdults
        }}</span>
        <v-icon x-small color="grey darken-4">mdi-account</v-icon>
        <span>{{
          totalChildren,
        }}</span>
        <v-icon small color="grey darken-4">mdi-door</v-icon>
        <span>{{ form.rooms.length }}</span>
      </div>
    </template>

    <!-- タブレット・PC -->
    <div v-else>
      <v-menu
        v-model="menu"
        offset-y
        transition="scale-transition"
        persistent
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on, attrs }">
          <div>
            <v-text-field
              :value="
                $t('plans.searchPanelRoomMapSelector.adultChildRoomNum', {
                  totalAdults: totalAdults,
                  totalChildren: totalChildren,
                  totalRoom: form.rooms.length,
                })
              "
              v-bind="attrs"
              v-on="on"
              style="font-size: 1.1em; background: white"
              height="45px"
              outlined
              dense
              readonly
              hide-details="auto"
            >
              <template v-slot:prepend-inner>
                <v-icon class="mt-1"> mdi-account </v-icon>
              </template>
              <template v-slot:label>
                <span class="pl-1">{{
                  $t("plans.searchPanelRoomMapSelector.personRoom")
                }}</span>
              </template>
            </v-text-field>
          </div>
        </template>

        <v-form
          ref="form"
          v-model="valid"
          :disabled="form.loading"
          @submit.prevent="apply()"
        >
          <v-card
            class="search-panel-room-selector"
            style="height: 400px; width: 100%"
          >
            <v-card-text style="height: 340px; overflow: scroll">
              <v-sheet v-for="(r, i) in form.rooms" :key="i">
                <div class="d-flex align-center">
                  <v-chip small>
                    {{ $t("plans.searchPanelRoomMapSelector.room") }}{{ i + 1 }}
                  </v-chip>
                  <div
                    :class="
                      $vuetify.breakpoint.sm || $vuetify.breakpoint.md
                        ? 'ml-6'
                        : ''
                    "
                  >
                    <div class="d-flex align-center">
                      <v-btn
                        icon
                        color="light-blue darken-1"
                        class="mx-1"
                        :disabled="r.numberOfAdults == 1"
                        @click="r.numberOfAdults--"
                      >
                        <v-icon class="px-0">mdi-minus</v-icon>
                      </v-btn>
                      <span>{{ $t("portal.adult") }}</span>
                      <div class="text-h6 mx-2 text-center" style="width: 20px">
                        {{ r.numberOfAdults }}
                      </div>
                      <span>{{ $t("portal.pax") }}</span>
                      <v-btn
                        icon
                        color="light-blue darken-1"
                        class="mx-1"
                        :disabled="
                          !!maxAdultPerRoom &&
                          r.numberOfAdults == maxAdultPerRoom
                        "
                        @click="r.numberOfAdults++"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                    <div class="d-flex align-center">
                      <v-btn
                        icon
                        color="light-blue darken-1"
                        class="mx-1"
                        :disabled="r.numberOfChildren == 0"
                        @click="r.numberOfChildren--"
                      >
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                      <div class="d-flex align-center">
                        <span>{{ $t("portal.child") }}</span>
                        <div
                          class="text-h6 mx-2 text-center"
                          style="width: 20px"
                        >
                          {{ r.numberOfChildren }}
                        </div>
                        <span>{{ $t("portal.pax") }}</span>
                      </div>
                      <v-btn
                        icon
                        color="light-blue darken-1"
                        class="mx-1"
                        :disabled="
                          !!maxChildPerRoom &&
                          r.numberOfChildren == maxChildPerRoom
                        "
                        @click="r.numberOfChildren++"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </div>
                  </div>
                  <v-btn
                    icon
                    color="red"
                    :disabled="i === 0"
                    @click="removeRoom(i)"
                  >
                    <v-icon v-if="i != 0" color="red">mdi-delete</v-icon>
                  </v-btn>
                </div>
                <v-divider class="my-2"></v-divider>
              </v-sheet>
              <!-- 部屋追加 -->
              <div class="text-right">
                <v-btn
                  v-if="
                    !maxRoomsPerBooking ||
                    form.rooms.length < maxRoomsPerBooking
                  "
                  color="secondary"
                  text
                  data-test="add-room"
                  @click="addRoom"
                >
                  <v-icon class="mr-1">mdi-plus-circle</v-icon>
                  {{ $t("plans.searchPanelRoomMapSelector.addRoom") }}
                </v-btn>
              </div>
            </v-card-text>
            <v-card-actions class="pt-0 pb-3 mx-2" style="height: 60px">
              <v-btn
                color="secondary"
                class="white--text font-weight-bold"
                style="width: 100%"
                @click="menu = false"
              >
                {{ $t("portal.decide") }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-menu>
    </div>
  </div>
</template>

<script>
import FrameModal from "@/components/FrameModal.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    FrameModal,
  },
  data() {
    return {
      menu: false,
      isOpen: false,
      form: {
        rooms: [
          {
            numberOfAdults: 2,
            numberOfChildren: 0,
          },
        ],
      },
      hideChild: false,
      valid: true,
    };
  },
  props: {
    value: { type: Array, default: () => [] },
    smallPanel: { type: Boolean, default: false },
  },
  watch: {
    form: {
      handler() {
        this.$emit("input", this.roomMap);
      },
      deep: true,
    },
  },
  async mounted() {
    if (!this.value || !this.value.length) return;
    const rooms = await Promise.all(
      this.value.map(async (r) => {
        const paxArr = cloneDeep(r).split("-");
        const adults = await paxArr.filter((e) => e === "A");
        const children = await paxArr.filter((e) => e[0] === "C");
        return {
          numberOfAdults: adults.length,
          numberOfChildren: children.length,
        };
      })
    );
    this.form.rooms = rooms;
  },
  computed: {
    maxAdultPerRoom() {
      return this.portalDefaultSetting?.filter?.maxAdults || 0
    },
    maxChildPerRoom() {
      return this.portalDefaultSetting?.filter?.maxChildren || 0
    },
    maxRoomsPerBooking() {
      return this.portalDefaultSetting?.filter?.maxRooms || 0
    },
    totalAdults() {
      if (this.form.rooms.length === 0) return 0;
      const res = this.form.rooms
        .map((e) => e.numberOfAdults)
        .reduce((prev, current) => prev + current);
      return res;
    },
    totalChildren() {
      if (this.form.rooms.length === 0) return 0;
      const res = this.form.rooms
        .map((e) => e.numberOfChildren)
        .reduce((prev, current) => prev + current);
      return res;
    },
    roomMap() {
      return this.form.rooms.map((room) => {
        let arr = [];
        arr.push(...Array.from({ length: room.numberOfAdults }).map(() => "A"));
        arr.push(
          ...Array.from({ length: room.numberOfChildren }).map(() => "C")
        );
        arr = arr.join("-");
        return arr;
      });
    },
  },
  methods: {
    childrenChange(e, i) {
      this.form.rooms[i].numberOfChildren = Number(e);
    },
    addRoom() {
      this.form.rooms.push({
        numberOfAdults: 2,
        numberOfChildren: 0,
      });
    },
    removeRoom(index) {
      this.form.rooms.splice(index, 1);
    },
    apply() {
      if (!this.$refs.form.validate()) return;
      this.isOpen = false;
      this.modalClose();
    },
  },
};
</script>

<style lang="scss" scoped>
#app .search-panel-room-selector input {
  font-size: 16px !important;
}
#mobile-fullscreen-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 300;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
