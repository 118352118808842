<template>
	<div
		v-if="!viewLoading"
		class="pb-4">
		<AppBar />
		<v-main>
			<v-img
				:src="heroImage"
				width="100%"
				:height="$vuetify.breakpoint.xs ? 250 + 'px' : 350 + 'px'"
				class="d-flex align-end"
				:class="[$vuetify.breakpoint.xs || 'pb-5']">
				<v-container
					v-if="!$vuetify.breakpoint.xs"
					class="d-flex justify-center">
					<SearchPanel
						v-model="search"
						:portalHotels="portalHotels"
						:areas="areas"
						:hotelTypes="hotelTypes"
						:hotelTags="hotelTags"
						:roomTags="roomTags"
						:meals="meals"
						:isThirdLayerSet="isThirdLayerSet"
						style="z-index: 1"
						:style="{
							width:
								$vuetify.breakpoint.sm || $vuetify.breakpoint.md
									? 100 + '%'
									: '',
						}" />
				</v-container>
			</v-img>
			<v-container
				v-if="$vuetify.breakpoint.xs"
				class="d-flex align-end"
				:class="!search.smallPanel || 'pa-0'"
				:style="
					search.smallPanel
						? 'z-index: 30; position: sticky; top: 56px'
						: 'z-index: 10'
				">
				<SearchPanelForMobile
					v-model="search"
					:portalHotels="portalHotels"
					:areas="areas"
					:hotelTypes="hotelTypes"
					:hotelTags="hotelTags"
					:roomTags="roomTags"
					:meals="meals"
					:isThirdLayerSet="isThirdLayerSet"
					style="z-index: 1; width: 100%"
					:style="{
						'margin-top': search.smallPanel ? '' : -100 + 'px',
					}"
					@small-panel="search.smallPanel = $event" />
			</v-container>

			<v-container
				:class="$vuetify.breakpoint.xs && search.smallPanel ? 'pt-6' : 'pt-4'"
				:style="{
					width:
						$vuetify.breakpoint.xs || $vuetify.breakpoint.sm
							? 100 + '%'
							: 1200 + 'px',
				}">
				<section
					:class="$vuetify.breakpoint.xs && search.smallPanel ? '' : 'mt-4'">
					<v-alert
						v-if="isChildSelected"
						dense
						type="info"
						outlined
						class="text-body-2 mt-2 mb-4">
						{{ $t('portal.childNotice') }}
					</v-alert>
					<header class="font-weight-bold">
						{{ $t('portal.facilityList') }}
						<span
							v-if="!searchLoading && hotels.length"
							class="ml-2 font-weight-regular text-body-2"
							>{{ $t('portal.isReferencePrice') }}</span
						>
					</header>
					<div class="mt-4">
						<div
							v-if="searchLoading"
							class="text-center">
							<v-progress-circular
								:width="3"
								color="primary"
								indeterminate></v-progress-circular>
						</div>
						<div v-else>
							<v-row v-if="hotels.length">
								<v-col
									v-for="(hotel, i) in hotels"
									:key="i"
									xl="4"
									lg="4"
									md="6"
									sm="6"
									cols="12">
									<Hotel
										:hotel="hotel"
										:url="guestSiteUrl(hotel)" />
								</v-col>
							</v-row>
							<div
								v-else
								class="text-center mt-10 grey--text text--darken-2">
								{{ $t('portal.noPlansFound') }}
							</div>
						</div>
					</div>
				</section>
			</v-container>
		</v-main>
	</div>
</template>

<script>
import AppBar from './PortalSite/AppBar';
import Hotel from './PortalSite/Hotel.vue';
import SearchPanel from './PortalSite/SearchPanel.vue';
import SearchPanelForMobile from './PortalSite/SearchPanelForMobile.vue';

import {PortalHotel} from '@/models';

import {meals} from '../mockData/data.json';
import {cloneDeep} from 'lodash';
import SearchMixin from '@/mixins/Search.js';

export default {
	mixins: [SearchMixin],
	components: {
		AppBar,
		Hotel,
		SearchPanel,
		SearchPanelForMobile,
	},
	props: {
		portalHotels: Array,
		areas: Array,
		areasAll: Array,
		hotelTypes: Array,
		hotelTags: Array,
		roomTags: Array,
		isThirdLayerSet: Boolean,
	},
	data: () => ({
		search: {
			checkedInOn: '',
			checkedOutOn: '',
			night: null,
			roomMap: null,
			hotelId: '',
			promotionCode: '',
			selectedArea: [],
			selectedHotelTypes: [],
			selectedHotelTags: [],
			selectedRoomTags: [],
			selectedMeals: [],
			smallPanel: false,
		},
		hotels: [],
		meals: meals,
		searchLoading: false,
		isChildSelected: false,
	}),
	async created() {
		this.dataReady = false;
		this.searchLoading = true;
		if (!this.$route.query.checkedInOn || !this.$route.query.checkedOutOn) {
			this.searchLoading = false;
			this.dataReady = true;
			return;
		}
		await this.syncQuery();
		await Promise.all([this.getHotels(), this.syncAreaQuery()]);
		await this.scrollTop();
		this.searchLoading = false;
		this.dataReady = true;
	},
	computed: {
		portalSiteId() {
			return this.$route.params.id;
		},
		heroImage() {
			return this.portalDefaultSetting.heroImages
				? this.portalDefaultSetting.heroImages[0]?.url
				: 'https://picsum.photos/id/323/3831/2554';
		},
	},
	methods: {
		async getHotels() {
			if (!this.query.checkedInOn || !this.query.checkedOutOn) return;

			const roomMap = this.query.roomMap.split(',').map(d => {
				if (d.includes('C')) {
					const data = d.split('-');
					return data.filter(d => d !== 'C').join('-');
				}
				return d;
			});

			const query = {
				...this.query,
				roomMap: roomMap.join(','),
			};
			const hotels = await PortalHotel.index(this.portalSiteId, query);

			const fixedHotels = hotels.map(hotel => {
				const areas = hotel.areas.filter(area => !area.parentId);
				const areaNames = areas.map(area => {
					const childAreas = hotel.areas.filter(
						child => child.parentId === area.id,
					);
					if (!childAreas.length) return this.getLabel(area.name);
					return childAreas.map(childArea => {
						const grandChildAreas = hotel.areas.filter(
							grandChild => grandChild.parentId === childArea.id,
						);
						if (!grandChildAreas.length)
							return `${this.getLabel(area.name)} ${this.getLabel(
								childArea.name,
							)}`;
						return grandChildAreas.map(
							grandChildArea =>
								`${this.getLabel(area.name)} ${this.getLabel(
									childArea.name,
								)} ${this.getLabel(grandChildArea.name)}`,
						);
					});
				});
				return {
					...hotel,
					hotelType: hotel.hotelType || {ja: 'その他', en: 'Other'},
					areaNames: areaNames,
					plan_count: hotel.matchedPlanInfo.planCount,
					min_price: hotel.matchedPlanInfo.priceMin,
					max_price: hotel.matchedPlanInfo.priceMax,
					hotel_image: hotel.image.url,
					special_code_flg: hotel.matchedPlanInfo.isSecret,
				};
			});
			this.hotels.splice(0);
			this.hotels.push(...fixedHotels);
		},
		async syncQuery() {
			let roomMap = [];
			if (this.$route.query.roomMap) {
				roomMap = cloneDeep(this.$route.query.roomMap).split(',');
				this.isChildSelected = roomMap.some(d => d.includes('C'));
			}

			await Promise.all(
				Object.keys(this.$route.query).map(key => {
					switch (key) {
						case 'roomMap':
							this.search.roomMap = this.$route.query.roomMap
								? cloneDeep(this.$route.query.roomMap).split(',')
								: [];
							break;
						case 'portalSiteSpecialCode':
							this.search.promotionCode =
								this.$route.query.portalSiteSpecialCode;
							break;
						case 'areaIds': {
							break;
						}
						case 'hotelTypeIds':
							this.search.selectedHotelTypes = this.$route.query.hotelTypeIds
								? this.$route.query.hotelTypeIds.split(',')
								: [];
							break;
						case 'hotelTagIds':
							this.search.selectedHotelTags = this.$route.query.hotelTagIds
								? this.$route.query.hotelTagIds.split(',')
								: [];
							break;
						case 'roomTagIds':
							this.search.selectedRoomTags = this.$route.query.roomTagIds
								? this.$route.query.roomTagIds.split(',')
								: [];
							break;
						case 'meal':
							this.search.selectedMeals = this.$route.query.meal
								? this.$route.query.meal.split(',')
								: [];
							break;
						case 'smallPanel':
							this.search.smallPanel = JSON.parse(this.$route.query.smallPanel);
							break;
						default:
							this.search[key] = this.$route.query[key];
							break;
					}
				}),
			);
		},
		async syncAreaQuery() {
			if (!this.$route.query.areaIds) return;
			let areaIdsArr = this.$route.query.areaIds
				? this.$route.query.areaIds.split(',')
				: [];
			if (!areaIdsArr.length || !this.areasAll.length) return;
			this.search.selectedArea = areaIdsArr.map(areaId => {
				const area = this.areasAll.find(d => d.id == areaId);
				const childAreas = area.childAreas.length
					? area.childAreas.map(d => {
							const grandChildAreas = d.childAreas.length
								? d.childAreas.map(d => {
										return {
											id: d.id,
											title: d.title,
										};
									})
								: [];
							return {
								id: d.id,
								title: d.title,
								childAreas: grandChildAreas,
							};
						})
					: [];
				return {
					id: area.id,
					title: area.title,
					childAreas: childAreas,
				};
			});
		},
		guestSiteUrl(hotel) {
			let url = process.env.VUE_APP_GS_URL;
			const query = cloneDeep(this.$route.query);
			let queryData = {
				checkedInOn: query.checkedInOn,
				checkedOutOn: query.checkedOutOn,
				night: query.night,
				roomMap: query.roomMap,
				filter: query.meal || '',
				near: false,
				order: 'SUGGEST',
				portalSiteId: this.portalSiteId,
				lang: this.lang,
			};
			if (hotel.special_code_flg && query.portalSiteSpecialCode)
				this.$set(queryData, 'specialCode', query.portalSiteSpecialCode);

			let queryParams = new URLSearchParams(queryData).toString();
			queryParams = decodeURIComponent(queryParams);
			return `${url}/hotels/${hotel.hotelId}/plans?${queryParams}`;
		},
		scrollTop() {
			window.scrollTo({
				top: 0,
				behavior: 'instant',
			});
		},
	},
};
</script>

<style>
.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
	> .v-input__control
	> .v-input__slot,
.v-text-field.v-text-field--enclosed .v-text-field__details {
	padding: 0 10px 0 10px !important;
}
</style>