<template>
	<div
		v-if="dataReady"
		style="position: relative">
		<v-card
			class="plan"
			link>
			<v-img
				v-if="hotel.hotel_image"
				:src="hotel.hotel_image"
				width="100%"
				:aspect-ratio="2.5 / 1">
				<div class="fill-height bottom-gradient" />
			</v-img>
			<v-img
				v-else
				src="@/assets/noimage.png"
				width="100%"
				:aspect-ratio="2.5 / 1" />
			<!-- <div
        class="d-flex align-center"
        style="position: absolute; top: 5%; right: 4%"
      >
        <v-chip
          v-if="
            hotel.promotionCode &&
            hotel.promotionCode == $route.query.portalSiteSpecialCode
          "
          small
          dark
          class="font-weight-bold mr-2"
          color="rgb(129, 199, 132, 0.9)"
        >
          {{ `${$t("portal.promotionCode")}:${hotel.promotionCode}` }}
        </v-chip>
        <v-chip
          small
          dark
          class="font-weight-bold"
          color="rgb(160, 160, 160, 0.5)"
        >
          {{ getLabel(hotel.portal_site_type) }}
        </v-chip>
      </div> -->
			<div
				class="d-flex align-center"
				style="position: absolute; top: 5%; right: 4%">
				<v-chip
					v-if="
						hotel.matchedPlanInfo.isSecret && $route.query.portalSiteSpecialCode
					"
					small
					dark
					class="font-weight-bold mr-2"
					color="rgb(129, 199, 132, 0.9)">
					{{
						`${$t('portal.promotionCode')}:${
							$route.query.portalSiteSpecialCode
						}`
					}}
				</v-chip>
				<v-chip
					small
					dark
					class="font-weight-bold"
					color="rgb(160, 160, 160, 0.5)">
					{{ getLabel(hotel.hotelType.name) }}
				</v-chip>
			</div>
			<div
				style="
					position: absolute;
					bottom: 60px;
					left: 4%;
					right: 4%;
					font-size: 0.7em;
				">
				<div
					class="font-weight-bold mb-1"
					:class="
						hotel.hotel_image ? 'white--text' : 'grey--text text--darken-2'
					"
					style="font-size: 1.6em">
					{{ getLabel(hotel.title) }}
				</div>
				<div
					class="font-weight-bold pr-1"
					:class="
						hotel.hotel_image ? 'white--text' : 'grey--text text--darken-2'
					"
					:style="{
						'font-size': $vuetify.breakpoint.xs ? 1.2 + 'em' : 1.1 + 'em',
					}">
					<v-icon
						:color="hotel.hotel_image ? 'white' : 'grey--text text--darken-2'"
						x-small
						>mdi-map-marker</v-icon
					>
					<span>{{ hotel.areaNames.join(', ') }}</span>
				</div>
			</div>
			<v-card-text
				class="d-flex justify-space-between grey--text text--darken-4 font-weight-medium py-3 text-subtitle-1">
				<div
					class="d-flex align-center"
					style="width: 100%">
					<div>
						<div v-if="hotel.plan_count">
							<template v-if="lang === 'en'">
								<span
									v-if="isChildSelected"
									class="text-subtitle-2"
									style="font-size: 12px"
									>{{
										`${totalAdults} ${
											totalAdults > 1 ? 'Adults' : 'Adult'
										}(+${$t('portal.child2')})`
									}}
								</span>
								<span
									v-else
									class="text-subtitle-2"
									style="font-size: 12px"
									>{{
										`${totalAdults} ${totalAdults > 1 ? 'Adults' : 'Adult'}`
									}}
								</span>
							</template>
							<template v-else>
								<span
									v-if="isChildSelected"
									class="text-subtitle-2"
									style="font-size: 12px"
									>{{
										`${$t('portal.adult')}${totalAdults}${$t(
											'portal.pax',
										)}(+${$t('portal.child2')})`
									}}
								</span>
								<span
									v-else
									class="text-subtitle-2"
									style="font-size: 12px"
									>{{
										`${$t('portal.adult')}${totalAdults}${$t('portal.pax')}`
									}}
								</span>
							</template>
							<span style="font-weight: 600">{{
								$t('portal.priceRange', {
									paxNumber: totalAdults,
									min: hotel.min_price.toLocaleString(),
								})
							}}</span>
						</div>
						<div
							v-else
							class="text-subtitle-2">
							{{ $t('portal.noPlansFound') }}
						</div>
					</div>
					<div class="text-subtitle-2 ml-auto">
						{{
							`${$t('plans.toolBar.numberOfPlans')} ${hotel.plan_count} ${$t(
								'plans.toolBar.plansNum',
							)}`
						}}
					</div>
				</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import _ from 'lodash';
export default {
	created() {
		this.scrollTop();
		this.dataReady = true;
	},
	props: {
		hotel: Object,
	},
	computed: {
		isChildSelected() {
			return this.$route.query.roomMap.includes('C');
		},
		totalAdults() {
			const roomMap = _.cloneDeep(this.$route.query.roomMap);
			const roomMapJoin = roomMap.replace(',', '-');
			return roomMapJoin.split('-').filter(e => e === 'A').length;
		},
	},
	methods: {
		scrollTop() {
			window.scrollTo({
				top: 0,
				behavior: 'instant',
			});
		},
	},
};
</script>

<style scoped>
.bottom-gradient {
	background-image: linear-gradient(
		to top,
		rgba(0, 0, 0, 0.8) 0%,
		transparent 100px
	);
}
</style>s
