<template>
  <div>
    <v-menu open-on-hover bottom offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn small text v-bind="attrs" v-on="on" style="cursor: pointer">
          <div class="d-flex align-center">
            <v-icon> mdi-translate </v-icon>
            <v-icon x-small> mdi-chevron-down </v-icon>
          </div>
          <div class="text-caption text-capitalize">{{ langText }}</div>
        </v-btn>
      </template>

      <v-card class="px-2">
        <v-list dense>
          <v-list-item-group
            v-model="lang"
            color="primary"
            @change="changeLang"
          >
            <template v-for="(lang, i) in langs">
              <v-list-item :key="`lang-${i}`" class="" :value="lang.value">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="lang.label"
                    class="font-weight-medium"
                    style="font-size: 12px"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="i < langs.length - 1" :key="i"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";

export default {
  computed: {
    langText() {
      return this.langs.find((d) => d.value == this.lang)?.label;
    },
  },
  methods: {
    changeLang() {
      const query = cloneDeep(this.$route.query);
      query.lang = this.lang;
      this.$router.push({ query: query }, () => {});
      this.modalClose();
    },
  },
};
</script>

<style>
#app .lang-selector input {
  font-size: 16px !important;
}
</style>