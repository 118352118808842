<template>
  <div v-if="!smallPanel">
    <v-text-field
      :value="selectedAreaText"
      style="background: white"
      height="46px"
      class="mb-4"
      outlined
      dense
      readonly
      hide-details="auto"
      :label="selectedAreaText ? $t('portal.area') : ''"
      :placeholder="selectedAreaText ? '' : $t('portal.areaSelect')"
      @click="modalShow('areaSelectorModal')"
    >
      <template v-slot:prepend-inner>
        <v-icon class="mt-1"> mdi-map-marker </v-icon>
      </template>
    </v-text-field>
    <frame-modal :name="'areaSelectorModal'" fullscreen>
      <v-card class="pt-2 px-0" style="height: 100%">
        <div class="text-right py-2 px-2" style="height: 5%">
          <v-btn
            text
            icon
            small
            color="black"
            @click="
              modalClose();
              selectedArea = selectedArea.filter((v) => v);
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-text style="overflow: scroll; height: 85%" class="px-3">
          <v-list>
            <!-- 大エリア -->
            <v-list-item-group
              v-if="!isLargeAreaSelected"
              v-model="selectedArea[0]"
              color="primary"
              @change="selectedArea.splice(1, 2)"
            >
              <v-list-item class="px-2" @click="modalClose()">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t('portal.noSelect')"
                    class="font-weight-medium"
                    style="font-size: 16px"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <template
                v-for="(area, i) in areas.filter(
                  (d) => !d.type || d.type != 'noSelect'
                )"
              >
                <v-list-item :key="`first-${i}`" class="px-2" :value="area">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="getLabel(area.title)"
                      class="font-weight-medium"
                      style="font-size: 16px"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-divider v-if="i < areas.length - 1" :key="i"></v-divider>
              </template>
            </v-list-item-group>
            <!-- 中エリア -->
            <v-list-item-group
              v-else-if="
                isThirdLayerSet
                  ? isLargeAreaSelected && !isMediumAreaSelected
                  : isLargeAreaSelected
              "
              v-model="selectedArea[1]"
              color="primary"
              @change="
                selectedArea.splice(2, 1);
                checkSelection(selectedArea[1]);
              "
            >
              <v-list-item
                class="px-2 indigo--text text--darken-4"
                @click="selectedArea[0] = ''"
              >
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t('common.back')"
                    class="font-weight-medium"
                    style="font-size: 16px"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <template
                v-for="(area, i) in selectedArea[0].childAreas.filter(
                  (d) => !d.type || d.type != 'noSelect'
                )"
              >
                <v-list-item :key="`second-${i}`" class="px-2" :value="area">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="getLabel(area.title)"
                      class="font-weight-medium"
                      style="font-size: 16px"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="i < selectedArea[0].childAreas.length - 1"
                  :key="i"
                ></v-divider>
              </template>
            </v-list-item-group>
            <!-- 小エリア -->
            <v-list-item-group
              v-else-if="
                isThirdLayerSet && isLargeAreaSelected && isMediumAreaSelected
              "
              v-model="selectedArea[2]"
              color="primary"
              @change="checkSelection(selectedArea[2])"
            >
              <v-list-item class="px-2 indigo--text text--darken-4">
                <v-list-item-content>
                  <v-list-item-title
                    v-text="$t('common.back')"
                    class="font-weight-medium"
                    style="font-size: 16px"
                    @click="selectedArea[1] = ''"
                  />
                </v-list-item-content>
              </v-list-item>
              <v-divider></v-divider>
              <template
                v-for="(area, i) in selectedArea[1].childAreas.filter(
                  (d) => !d.type || d.type != 'noSelect'
                )"
              >
                <v-list-item :key="`third-${i}`" class="px-2" :value="area">
                  <v-list-item-content>
                    <v-list-item-title
                      v-text="getLabel(area.title)"
                      class="font-weight-medium"
                      style="font-size: 16px"
                    />
                  </v-list-item-content>
                </v-list-item>
                <v-divider
                  v-if="i < selectedArea[1].childAreas.length - 1"
                  :key="i"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions class="pt-0 pb-3 mx-2" style="height: 10%">
          <v-btn
            color="secondary"
            class="white--text font-weight-bold"
            style="width: 100%"
            large
            @click="
              modalClose();
              selectedArea = selectedArea.filter((v) => v);
            "
          >
            {{ $t("portal.decide") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </frame-modal>
  </div>
  <div v-else class="font-weight-medium">
    {{ selectedAreaText }}
  </div>
</template>

<script>
import FrameModal from "@/components/FrameModal.vue";
import { cloneDeep } from "lodash";

export default {
  components: {
    FrameModal,
  },
  props: {
    value: { type: Array },
    areas: { type: Array },
    isThirdLayerSet: { type: Boolean },
    smallPanel: { type: Boolean, default: false },
  },
  data: () => ({
    show: false,
  }),
  watch: {
    selectedArea() {
      let isNoSelect = this.selectedArea.find((d) => d && !d.id);
      if (!isNoSelect) return;
      const index = this.selectedArea.indexOf(isNoSelect);
      if (index == 0) return;
      index == 1
        ? this.selectedArea.splice(1, 2)
        : this.selectedArea.splice(2, 1);
      this.modalClose();
    },
  },
  computed: {
    selectedArea: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    isLargeAreaSelected() {
      return this.selectedArea[0] && this.selectedArea[0].id;
    },
    isMediumAreaSelected() {
      return (
        this.selectedArea[1] &&
        this.selectedArea[1].id &&
        this.selectedArea[1].childAreas &&
        this.selectedArea[1].childAreas.length
      );
    },
    isSmallAreaSelected() {
      return this.selectedArea[2] && this.selectedArea[2].id;
    },
    selectedAreaText() {
      if (!this.selectedArea.length) return "";
      let selected = cloneDeep(this.selectedArea).filter((v) => v);
      if (!selected.length) return "";
      let text = this.getLabel(selected[selected.length - 1]?.title);
      return text;
    },
  },
  methods: {
    checkSelection(selectedArea) {
      if (
        !selectedArea ||
        !selectedArea.id ||
        (selectedArea.childAreas && selectedArea.childAreas.length)
      )
        return;
      this.modalClose();
    },
  },
};
</script>

<style>
.v-text-field input {
  color: red;
}
</style>